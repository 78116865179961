
(window as any).__zone_ignore_on_properties = [
  {
    target: window,
    ignoreProperties: ['message'],
  },
];

// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['message'];

(window as any).__zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__zone_disable_timers = true;
(window as any).__zone_disable_on_property = true; // disable patch onProperty such as onclick
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['message', 'scroll',
'mouseenter',
'mouseleave',
'mousemove',
'mouseover',
'mouseout',
'mousewheel',]; // disable patch specified eventNames
(window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll'];
(window as any).__zone_symbol__PASSIVE_EVENTS = [
  'scroll',
  'mousewheel',
  'wheel',
  'touchstart'
];

// Zone[Zone.__symbol__('BLACK_LISTED_EVENTS')] = [
//   'scroll',
//   'mouseenter',
//   'mouseleave',
//   'mousemove',
//   'mouseover',
//   'mouseout',
//   'mousewheel',
// ];

export {};
